<script>
import HeroBar from '@/components/HeroBar.vue'
import TitleBarNew from '@/components/TitleBarNew.vue'

export default {
  name: 'ShipmentTypes',
  components: { TitleBarNew, HeroBar },
  data () {
    return {
      titleStack: [
        {
          name: 'Dashboard',
          routeName: 'home',
          params: []
        },
        {
          name: 'Modes'
        }
      ]
    }
  }
}
</script>

<template>
  <div>
      <title-bar-new :title-stack="titleStack" />
      <hero-bar>
        Modes
      </hero-bar>

    <section class="section is-main-section has-background-white">
      <div class="mb-2 p-4">
        <ol>
          <li>
            <router-link :to="{name: 'shipment.type', params: {type: 'Ocean Cargo'}}">Ocean Cargo</router-link>
          </li>
          <li>
            <router-link :to="{name: 'shipment.type', params: {type: 'Air Cargo'}}">Air Cargo</router-link>
          </li>
        </ol>
      </div>
    </section>
  </div>
</template>

<style scoped>

</style>
